<template>
  <v-app class>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div>
              <v-alert v-model="alert"
                       border="left"
                       dark
                       dismissible
                       class="alert"
                       :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
                {{alertMessage}}
              </v-alert>
            </div>
            <div class="col-md-11 text-center">
              <h1>Union Membership</h1>
            </div>
            <div class="col-md-1">
              <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2" />
            </div>
          </div>
          <!-- <div class="col-md-6 ml-auto mr-auto">
            <v-card class="mx-auto my-12">
                <v-form ref="UnionMembershipForm" v-model="valid">
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="12">
                            <TextField v-model="formData.name" :label="'Name'" />
                            </v-col>
                            <v-col cols="12">
                            <TextField v-model="formData.description" :label="'Description'" />
                            </v-col>
                            <v-col cols="12">
                            <TextField v-model="formData.markup" :label="'Mark Up'" />
                            </v-col>
                        </v-row>
                        <v-col cols="9 mt-5" class="d-flex justify-end">
                            <Button
                            :label="'Submit'"
                            :btnType="'Submit'"
                            @onClick="dialog = true"
                            :disabled="!valid"
                            :isLoading="loadingData"
                            class="mr-4"
                            />
                            <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
                        </v-col>
                    </v-card-text>
                </v-form>
            </v-card>
          </div> -->
          <div v-if="loadingData" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
           <v-data-table v-else
                         :headers='headers'
                         :items="unionMembership"
                         item-key="id">

                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex">
                        <Button :btnType="'Submit'" :color="'success'" :label="'Approve'" @onClick="ApproveItem(item)" class="mr-2" />
                        <!-- <Button :btnType="'Cancel'" :label="'Reject'" @onClick="RejectItem(item)" class="mr-2" /> -->
                    </div>
                    </template>
           </v-data-table>
        </v-container>
      </div>
    </div>
    <!-- <ConfirmationDialog :title="'Are you sure you want to delete?'" :isLoading="loading" :dialog="dialogDel" :btnTitle="'Yes'" @close="dialogDel = false" @btnAction="onReject"/> -->

    <!-- <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="loadingData" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/> -->

    <ConfirmationDialog :title="'Are you sure you want to Approve?'" :isLoading="loadingData" :dialog="dialogApprove" :btnTitle="'Yes'" @close="dialogApprove = false" @btnAction="onApprove"/>
  </v-app>
</template>
<script>
import Button from '@/components/ui/form/Button.vue'
// import Dialog from '@/components/ui/Dialog.vue'
// import SelectField from '@/components/ui/form/SelectField.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { unionMemberService } from '@/services'
// import TextField from '@/components/ui/form/TextField'
export default {
  components: {
    // TextField,
    Button,
    ConfirmationDialog
    // Dialog
    // SelectField
  },
  data () {
    return {
    //   formData: {
    //     name: '',
    //     description: '',
    //     markup: ''
    //   },
      pay: [],
      approval: [],
      loadingData: false,
      loading: false,
      dialog: false,
      dialogApprove: false,
      dialogDel: false,
      valid: false,
      unionMembership: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'MarkUp',
          value: 'markup'
        }
        // {
        //   text: 'Actions',
        //   value: 'actions'
        // }
      ]
    }
  },
  computed: {
    // user () {
    //   return this.$store.getters.users
    // }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    Add () {
      this.$router.push('/union-membership')
    },
    getUnionMembership () {
      unionMemberService.getAllUnionMembers().then(result => {
        this.unionMembership = result.data.items
      }).catch(error => {
        console.log(error)
      })
    },

    cancel () {
    },

    ApproveItem (item) {
      this.approval = item
      this.dialogApprove = true
    },
    onApprove () {
      this.loadingData = true
      console.log('Ok')
      const data = {
        Comment: '',
        Status: 1,
        Id: this.approval.id
      }
      console.log('Approve is Here')

      unionMemberService.approveMmebership(data).then(() => {
        this.showAlertMessage('Union Membership successfully Approved', 'success')
      }).catch((error) => {
        console.log('Error: ', error)
        this.showAlertMessage('Approval Union Membership failed', 'error')
        this.loadingData = false
        this.dialogApprove = false
      }).finally(() => {
        this.loadingData = false
        this.dialogApprove = false
        this.onReset()
      })
    },
    onReset () {
    //   this.$refs.UnionMembershipForm.reset()
    //   this.formData.name = ''
    //   this.formData.description = ''
    //   this.formData.markup = ''
    },
    openDeleteModal (item) {
    //   this.data = item
    //   console.log(item)
    //   this.dialog = true
    }

  },
  mounted () {
    this.getUnionMembership()
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .iconAvatar {
    width: 122px;
  }

  .text-caption {
    font-weight: 600;
    font-size: 14px !important
  }
  .sticky-col {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
  }
  .cursor {
    cursor: pointer;
  }

    .cursor:hover {
      color: blue
    }

    .cursor .tooltiptext {
      visibility: hidden;
      width: 80px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 2px 0;
      font-size: 10px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }

    .cursor:hover .tooltiptext {
      visibility: visible;
    }
</style>
